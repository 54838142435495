<template>
  <div class="exercise-subpage">
    <div class="d-flex flex-column justify-content-center">
      <Entry title="Passing Maestro"
             img_name="diamond-agility.png"
             description="This exercise is familiar with the Drop-Out exercise and can be done with or without ball. One difference: The players in this exercise go alternately (one after another) in a specific order. When a SmartGoal lights up there is only one player that has to run. | Skill: acceleration, awareness, reaction speed | Coach on: dribbling in place, stand on front feet | Physical load: calfs, hamstrings, quadriceps | Keep them focussed!"/>

      <Entry title="The blind spot passing test"
             img_name="off-agility.png"
             description="This exercise is familiar with the Drop-Out exercise and can be done with or without ball. One difference: The players in this exercise go alternately (one after another) in a specific order. When a SmartGoal lights up there is only one player that has to run. | Skill: acceleration, awareness, reaction speed | Coach on: dribbling in place, stand on front feet | Physical load: calfs, hamstrings, quadriceps | Keep them focussed!"/>
    </div>
  </div>
</template>

<script>
import Entry from "@/components/Entry";

export default {
  name: "PassingPage",
  components: {
    Entry
  }
}
</script>
